html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    height: 100%;
    margin: 0;
    line-height: 1.5;
    color: #121212;
}

#error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.demo-logo {
    width: 120px;
    min-width: 120px;
    height: 40px;
    background: rgba(255,255,255,.2);
    border-radius: 6px;
    margin-inline-end: 24px;
    line-height: 40px; /*设置line-height与父级元素的height相等*/
    text-align: center; /*设置文本水平居中*/
    overflow: hidden; /*防止内容超出容器或者产生自动换行*/
    font-size: 18px;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
}

.demo-logo a {
    color: aliceblue;
}